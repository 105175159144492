<template>
  <z-view class="is-home" style="background: #000 !important" size="xxl">
    <!-- <i  class="fab fa-github fa-4x"></i> -->
    <h1
      style="
        color: #fff;
        font-weight: 700;
        font-size: 3rem;
        position: relative;
        top: 0px;
        padding: 20px;
        box-sizing: border-box;
        text-transform: uppercase;
      "
    >
      Koniver Oral
      <span style="color: #4d610a !important">Supplements</span>
    </h1>
    <p style="color: #fff; font-size: 14px; padding: 20px; line-height: 20px">
      Listen to Dr. Craig Koniver and Dr. Keli Koniver on the Skinny
      Confidential Podcast as they explore Koniver Wellness’ unique approach to
      hormone regulation, health optimization, medical aesthetics, and the
      challenges and opportunities of modern medicine.
    </p>
    <div slot="extension">
      <z-spot
        class="asteroids"
        style="
          background-color: #324041;
          border-width: 3px;
          border-color: white;
        "
        size="s"
        :distance="150"
        :angle="-65"
      >
      </z-spot>
      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 3px;
          border-color: white;
        "
        size="s"
        :distance="160"
        :angle="-130"
      >
      </z-spot>
      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 3px;
          border-color: white;
        "
        size="s"
        :distance="150"
        :angle="140"
      >
      </z-spot>
      <z-spot
        class="asteroids"
        style="
          background-color: #324041;
          border-width: 15px;
          border-color: white;
        "
        size="s"
        :distance="130"
        :angle="75"
      />
      <z-spot
        class="asteroids stay"
        style="
          background-color: #324041;
          border-width: 6px;
          border-color: white;
        "
        size="xs"
        :distance="160"
        :angle="-47"
      />
      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 7px;
          border-color: white;
        "
        size="xs"
        :distance="185"
        :angle="160"
      ></z-spot>
      <z-spot
        class="asteroids"
        style="
          background-color: #324041;
          border-width: 9px;
          border-color: white;
        "
        size="s"
        :distance="130"
        :angle="-110"
      />

      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 3px;
          border-color: white;
        "
        size="s"
        :distance="180"
        :angle="94"
      >
      </z-spot>

      <z-spot
        class="asteroids"
        style="
          background-color: #324041;
          border-width: 3px;
          border-color: white;
        "
        size="xs"
        :distance="148"
        :angle="0"
      >
      </z-spot>
      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 3px;
          border-color: white;
        "
        size="xs"
        :distance="160"
        :angle="110"
      >
      </z-spot>
      <z-spot
        class="asteroids"
        style="
          background-color: #324041;
          border-width: 1px;
          border-color: white;
        "
        size="xxs"
        :distance="148"
        :angle="43"
      />
      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 1px;
          border-color: white;
        "
        size="xxs"
        :distance="122"
        :angle="113"
      />
      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 1px;
          border-color: white;
        "
        size="xxs"
        :distance="132"
        :angle="210"
      />
      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 1px;
          border-color: white;
        "
        size="xxs"
        :distance="132"
        :angle="-82"
      />
      <z-spot
        class="asteroids"
        style="
          background-color: #324041;
          border-width: 3px;
          border-color: white;
        "
        size="xs"
        :distance="190"
        :angle="-160"
      >
      </z-spot>

      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 3px;
          border-color: white;
        "
        size="xs"
        :distance="190"
        :angle="angle + 130"
      >
      </z-spot>
      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 3px;
          border-color: white;
        "
        size="xs"
        :distance="200"
        :angle="-55"
      >
      </z-spot>
      <z-spot
        class="asteroids"
        style="
          background-color: #324041;
          border-width: 3px;
          border-color: white;
        "
        size="s"
        :distance="250"
        :angle="-155"
      >
      </z-spot>
      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 3px;
          border-color: white;
        "
        size="xs"
        :distance="250"
        :angle="-166"
      >
      </z-spot>
      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 3px;
          border-color: white;
        "
        size="xs"
        :distance="230"
        :angle="-195"
      >
      </z-spot>
      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 3px;
          border-color: white;
        "
        size="xs"
        :distance="210"
        :angle="-125"
      >
      </z-spot>
      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 3px;
          border-color: white;
        "
        size="s"
        :distance="-230"
        :angle="150"
      >
      </z-spot>
      <z-spot
        class="asteroids"
        style="
          background-color: var(--shade-color);
          border-width: 3px;
          border-color: white;
        "
        size="xs"
        :distance="-235"
        :angle="165"
      >
      </z-spot>

      <z-spot
        class="meteors"
        :distance="170"
        :angle="-30"
        ref="repos"
        style="
          color: white;
          font-size: 40px;
          border-color: white;
          border-width: 3px;
          background-color: var(--shade-color);
        "
        @mouseup.native="renderMe('repos')"
        @wheel.native.prevent="forward($event, 'repos')"
        label="Subscribe"
      >
        <i class="fas fa-envelope"></i>
      </z-spot>
      <z-spot
        class="meteors"
        :distance="160"
        ref="marketplacr"
        :angle="20"
        style="
          color: white;
          font-size: 40px;
          border-color: white;
          border-width: 3px;
          background-color: #324041;
        "
        @click.native="
          toLink(
            'https://client.koniverwellness.com/injectable-weight-loss-therapy/'
          )
        "
        label="Client Marketplace"
      >
        <i class="fas fa-shopping-basket"></i>
      </z-spot>
      <z-spot
        button
        class="meteors"
        :distance="160"
        :angle="-180"
        size="s"
        ref="about"
        style="
          color: white;
          font-size: 25px;
          border-color: white;
          border-width: 0px;
          background-color: var(--shade-color);
        "
        label="About Us"
        @mouseup.native="renderMe('about')"
        @wheel.native="forward($event, 'about')"
        ><i class="fas fa-question"></i>
      </z-spot>
      <z-spot
        button
        class="meteors"
        :distance="210"
        :angle="-141"
        size="m"
        ref="peptidesview"
        style="
          color: white;
          font-size: 25px;
          border-color: var(--shade-color);
          border-width: 1px;
          background: #324041;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        "
        label="Peptides"
        @mouseup.native="renderMe('peptidesview')"
        @wheel.native="forward($event, 'peptidesview')"
      >
        <i class="fas fa-prescription-bottle-alt"></i>
      </z-spot>
      <z-spot
        button
        class="meteors"
        :distance="237"
        :angle="-177"
        size="m"
        ref="weightloss"
        style="
          color: white;
          font-size: 25px;
          border-color: var(--shade-color);
          border-width: 1px;
          background: var(--shade-color);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        "
        label="Weight Loss"
        @mouseup.native="renderMe('weightloss')"
        @wheel.native="forward($event, 'weightloss')"
      >
        <i class="fas fa-weight"></i>
      </z-spot>
      <z-spot
        button
        class="meteors"
        :distance="-237"
        :angle="181"
        size="m"
        ref="wellnesscons"
        style="
          color: white;
          font-size: 25px;
          border-color: var(--shade-color);
          border-width: 1px;
          background: var(--shade-color);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        "
        label="Wellness Consultation"
        @mouseup.native="renderMe('wellnesscons')"
        @wheel.native="forward($event, 'wellnesscons')"
      >
        <i class="fas fa-spa"></i>
      </z-spot>
      <z-spot
        button
        class="meteors"
        :distance="-245"
        :angle="211"
        size="m"
        ref="supplements"
        style="
          color: white;
          font-size: 25px;
          border-color: var(--shade-color);
          border-width: 1px;
          background: var(--shade-color);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        "
        label="Supplementations"
        @mouseup.native="renderMe('supplements')"
        @wheel.native="forward($event, 'supplements')"
      >
        <i class="fas fa-tablets"></i>
      </z-spot>
      <z-spot
        button
        class="meteors"
        :distance="223"
        :angle="-213"
        size="m"
        ref="nutrienttherapy"
        style="
          color: white;
          font-size: 25px;
          border-color: var(--shade-color);
          border-width: 1px;
          background: #324041;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        "
        label="IV Nutrient Therapy"
        @mouseup.native="renderMe('nutrienttherapy')"
        @wheel.native="forward($event, 'nutrienttherapy')"
      >
        <i class="fab fa-pagelines"></i>
      </z-spot>
      <z-spot
        class="meteors"
        size="s"
        style="color: white; border-color: white; background-color: #324041"
        :angle="50"
        :distance="180"
        label="Contact"
        ref="contact"
        @mouseup.native="renderMe('contact')"
        @wheel.native="forward($event, 'contact')"
      >
        <i
          class="fas fa-ellipsis-v"
          style="font-size: 18px; line-height: 40px"
        ></i>
      </z-spot>
    </div>
  </z-view>
</template>

<script>
import state from "../store/state";
import anime from "animejs";
export default {
  data() {
    return {
      time: false,
      collection: [],
      sharedState: state.$data,
      angle: 0,
      dessvs: [],
      devs1: [],
      angle1: 0,
      ani: {},
      hideThis: "",
      colors: [
        {
          main: "#b3b309",
          sec: "hsl(222, 92%, 25%)",
        },
      ],
    };
  },
  computed: {
    home() {
      return this.$zircle.getCurrentViewName();
    },
  },
  watch: {
    home() {
      if (this.home === "home--0") this.callRandomColors();
    },
  },
  methods: {
    hideMe(ref) {
      this.hideThis = ref;
      this.$refs[ref].$el.style.opacity = 0;
    },
    forward(e, view) {
      if (e.deltaY < 0 && this.$zircle.getCurrentViewName() === "home--0") {
        this.renderMe(view);
      }
    },
    renderMe(ref) {
      this.sharedState.initRepos = true;
      this.$zircle.toView({
        to: ref,
        fromSpot: this.$refs[ref],
      });
    },
    toLink(url) {
      return window.open(url, "_blank");
    },
    callRandomColors() {
      var randomColor =
        this.colors[Math.floor(Math.random() * this.colors.length)];
      this.sharedState.colorMe = randomColor;
      document
        .querySelector(".theme-github")
        .style.setProperty("--accent-color", randomColor.sec);
      document
        .querySelector(".theme-github")
        .style.setProperty("--shade-color", randomColor.main);
      return randomColor;
    },
    asteroids() {
      var vm = this;
      var angles = {
        degree: 0,
      };
      anime({
        targets: angles,
        degree: 360,
        duration: 300000,
        easing: "linear",
        update: function () {
          vm.angle = angles.degree;
        },
      });
    },
    meteors() {
      var vm = this;
      var angles = {
        degree: 0,
      };
      vm.ani = anime({
        targets: angles,
        degree: 360,
        duration: 120000,
        elasticity: 100,
        easing: "linear",
        update: function () {
          vm.angle1 = angles.degree;
        },
      });
    },
  },
  mounted() {
    var vm = this;
    setTimeout(function () {
      vm.callRandomColors();
    }, 1500);
  },
};
</script>

<style>
.asteroids {
  pointer-events: none !important;
  opacity: 0.3;
}

.is-past-view section div .asteroids {
  opacity: 0 !important;
}

.is-previous-view,
.is-past-view {
  opacity: 1 !important;
}

.is-previous-view section,
.is-past-view section {
  opacity: 1 !important;
}

.is-previous-view section div div .z-label,
.is-past-view section div div .z-label {
  opacity: 0 !important;
}

.is-previous-view section div div section div div div .extra,
.is-past-view section div div section div div div .extra {
  opacity: 0 !important;
}

.is-previous-view section .z-outer-circle,
.is-past-view section .z-outer-circle {
  opacity: 0 !important;
}

.is-previous-view {
  filter: blur(4px) opacity(85%) !important;
}

.is-past-view {
  filter: blur(4px) opacity(65%) !important;
}

.is-home.is-current-view section .z-outer-circle {
  opacity: 0 !important;
  cursor: default !important;
}

.is-current-view section .z-outer-circle {
  background-color: rgba(0, 0, 0, 0.01) !important;
  cursor: default !important;
}

.is-current-view section svg {
  cursor: default !important;
}

/* .is-previous-view section div div, .is-past-view section div div {
  opacity: 1 !important
}

.is-previous-view section .z-main-content, .is-past-view section .z-main-content {
  opacity: 0 !important
}
.is-previous-view section div .stay, .is-past-view section div .stay{
  opacity: 1 !important
}
.is-previous-view section div div section div div .stay, .is-past-view section div div section div div .stay{
  opacity: 1 !important
} */

.meteors {
  font-weight: 700;
}

.meteors > .z-label > .inside {
  color: #151515 !important;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.5) !important;
  font-size: calc(0.6vw + 0.6vh + 0.6vmin);
}

.meteors.sub {
  font-size: calc(0.6vw + 0.6vh + 0.6vmin);
}

.side > .z-label {
  text-align: left !important;
  padding: 0 !important;
  margin: 0 !important;
}

.side > .z-label > .inside {
  color: var(--accent-color) !important;
  font-weight: 500 !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: left !important;
  background-color: transparent !important;
  font-size: calc(2vw + 1vh + 1vmin);
}
</style>
