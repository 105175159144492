<template>
  <z-view style="border-width: 3px; background-color: white">
    <div class="contactForm">
      <form>
        <div class="field" tabindex="1">
          <label for="username"> <i class="far fa-user"></i>Your Name </label>
          <input
            name="username"
            type="text"
            placeholder="e.g. john doe"
            required
          />
        </div>
        <div class="field" tabindex="2">
          <label for="email"> <i class="far fa-envelope"></i>Your Email </label>
          <input
            name="email"
            type="text"
            placeholder="email@domain.com"
            required
          />
        </div>
        <div class="field" tabindex="3">
          <label for="message"> <i class="far fa-edit"></i>Your Message </label>
          <textarea name="message" placeholder="type here" required></textarea>
        </div>
        <button type="reset">Submit Form</button>
      </form>
    </div>

    <div slot="extension">
      <z-spot
        class="buttons"
        button
        size="xs"
        :distance="125"
        :angle="-45"
        label="Phone"
        style="border: none; color: white; background: #324041; color: #fff"
        ><i class="fas fa-phone" style="font-size: 16px; line-height: 40px"></i
      ></z-spot>
      <z-spot
        class="buttons"
        button
        size="xs"
        :distance="125"
        :angle="-20"
        label="Mail"
        style="border: none; color: white; background: #324041; color: #fff"
        ><i
          class="fas fa-envelope"
          style="font-size: 16px; line-height: 40px"
        ></i
      ></z-spot>
      <z-spot
        class="buttons"
        button
        size="xs"
        :distance="125"
        :angle="5"
        label="Adress"
        style="border: none; color: white; background: #324041; color: #fff"
        ><i
          class="fas fa-map-marker-alt"
          style="font-size: 16px; line-height: 40px"
        ></i
      ></z-spot>
    </div>
  </z-view>
</template>

<script>
// :angle="(180 - (180 - ($zircle.getNumberOfPages() * 10))) / $zircle.getNumberOfPages() * ($zircle.getNumberOfPages() - index) + ((180 - (180 - (180 - ($zircle.getNumberOfPages() * 10)))) - ((180 - (180 - ($zircle.getNumberOfPages() * 10))) / $zircle.getNumberOfPages())) / 2"
import state from "../store/state";
import axios from "axios";

function fetchGalleries(results, since, stateError) {
  return Promise.all(
    results.map((record) => {
      return axios
        .get(
          "https://gtrend.yapie.me/repositories?since=" +
            since +
            "&language=" +
            (record.urlParam === "c%23"
              ? record.urlParam
              : encodeURIComponent(record.urlParam))
        )
        .catch((err) => {
          console.log(err);
          stateError = err.message;
        });
    })
  ).then((gal) => {
    var papa = gal.filter(function (el) {
      return el.data.length > 0;
    });
    return papa.map((a) => a.data[0].language);
  });
}
export default {
  data() {
    return {
      time: false,
      popular: [],
      other: [],
      results: [],
      query: "",
      wt: [],
      sharedState: state.$data,
      searchActive: false,
    };
  },
  computed: {
    viewn() {
      return this.$zircle.getCurrentViewName();
    },
    language() {
      return this.query;
    },
  },
  watch: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.contactForm {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
form {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

form div,
form label,
form input,
form textarea {
  width: 100%;
  text-align: left;
}

.field:nth-of-type(2) {
  margin: 16px 0;
}

label,
input,
textarea {
  padding: 8px;
}

label,
[placeholder] {
  color: #555;
}

label i {
  margin: 0 10px 0 0;
}

.field:focus-within label {
  color: #000;
  letter-spacing: 2px;
}

input,
textarea {
  background: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 4px;
  box-shadow: 0 8px 6px -6px #555;
}
input:focus,
textarea:focus {
  background: white;
  box-shadow: none;
}

textarea {
  resize: none;
}
textarea::-webkit-scrollbar {
  width: 0;
}

button {
  background: #b3b309;
  margin: 16px 0 0px 0;
  padding: 8px 16px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 8px 6px -6px #555;
}
button:hover {
  letter-spacing: 2px;
  box-shadow: none;
}

.social-media {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
:not(.social-media) {
  font-size: 14px;
}
.social-media span {
  font-size: 16px;
}
.social-media span .fas {
  margin: 0 0 0 10px;
}
.social-media a {
  color: #000;
  font-size: 20px;
}

@media (max-width: 425px) {
  form {
    width: 100%;
  }
}
</style>
