<template>
  <div id="app" style="user-select: none">
    <div class="logo">
      <a href="/"><img src="/logo.png" alt="" /></a>
    </div>
    <transition name="head">
      <div v-if="$zircle.getCurrentViewName() === 'home--0'" class="footer">
        <!-- <span style="font-size: 13px">
          <i>Designed By. </i>
          <a href="https://seoland.com.tr" target="_blank"
            ><strong>Seoland</strong></a
          >
        </span> -->
      </div>
    </transition>
    <transition name="head">
      <div
        v-if="$zircle.getCurrentViewName() === 'repos--0'"
        class="title other"
      >
        <h1>Subscribe</h1>
      </div>
    </transition>
    <transition name="head">
      <div
        v-if="$zircle.getCurrentViewName() === 'repos--0'"
        class="footer other"
      >
        <!-- <span style="font-size: 13px"
          ><b>Tip: </b> use filter to change coding language & time period</span
        > -->
      </div>
    </transition>
    <transition name="head">
      <div v-if="$zircle.getCurrentViewName() === 'repo--0'" class="footer">
        <!-- <span style="font-size: 13px"
          ><b>Tip: </b> use insights to see repo evolution on chart</span
        > -->
      </div>
    </transition>
    <transition name="head">
      <div
        v-if="$zircle.getCurrentViewName() === 'devs--0'"
        class="title other"
      >
        <span style="text-transform: capitalize">{{ sharedState.since }}</span>
        trending developers
        <br />
        <div
          style="
            line-height: 0.8em;
            font-weight: 300;
            font-size: 20px;
            color: #8a8f94;
          "
        >
          <br />{{
            sharedState.language === ""
              ? "all coding contact"
              : sharedState.language
          }}
        </div>
      </div>
    </transition>
    <transition name="head">
      <div
        v-if="$zircle.getCurrentViewName() === 'about--0'"
        class="title other"
      >
        <h1>About us</h1>
      </div>
    </transition>
    <transition name="head">
      <div
        v-if="$zircle.getCurrentViewName() === 'peptidesview--0'"
        class="title other"
      >
        <h2>Peptides</h2>
      </div>
    </transition>
    <transition name="head">
      <div
        v-if="$zircle.getCurrentViewName() === 'weightloss--0'"
        class="title other"
      >
        <h2>Weight Loss</h2>
      </div>
    </transition>
    <transition name="head">
      <div
        v-if="$zircle.getCurrentViewName() === 'wellnesscons--0'"
        class="title other"
      >
        <h2>Wellness Consultation</h2>
      </div>
    </transition>
    <transition name="head">
      <div
        v-if="$zircle.getCurrentViewName() === 'supplements--0'"
        class="title other"
      >
        <h2>Advanced Oral Supplementations</h2>
      </div>
    </transition>
    <transition name="head">
      <div
        v-if="$zircle.getCurrentViewName() === 'nutrienttherapy--0'"
        class="title other"
      >
        <h2>IV Nutrient Therapy</h2>
      </div>
    </transition>
    <transition name="head">
      <div
        v-if="$zircle.getCurrentViewName() === 'devs--0'"
        class="footer other"
      >
        <!-- <span style="font-size: 13px"
          ><b>Tip: </b> use filter to change coding language & time period</span
        > -->
      </div>
    </transition>
    <transition name="head">
      <div
        v-if="$zircle.getCurrentViewName() === 'peptidtab2--0'"
        class="title other"
      >
        <h2>Peptides Tab 2</h2>
      </div>
    </transition>
    <transition name="head">
      <div
        v-if="$zircle.getCurrentViewName() === 'peptidtab2-1--0'"
        class="title other"
      >
        <h2>Peptides Tab 2 - 1</h2>
      </div>
    </transition>
    <transition name="head">
      <div
        v-if="$zircle.getCurrentViewName() === 'contact--0'"
        class="title other"
      >
        <h1>Contact</h1>
      </div>
    </transition>
    <transition name="head">
      <div v-if="$zircle.getCurrentViewName() === 'contact--0'" class="footer">
        <!-- Burası Contactin Alt Bölümü -->
      </div>
    </transition>
    <z-canvas
      :views="$options.components"
      @wheel.native="backward"
      @touchstart.native="startPos"
      @touchend.native="endPos"
    ></z-canvas>
  </div>
</template>

<script>
import home from "./components/home";
import repos from "./components/repos";
import peptidesview from "./components/peptidesview";
import devs from "./components/devs";
import contact from "./components/contact";
import repo from "./components/repo";
import dev from "./components/dev";
import about from "./components/about";
import weightloss from "./components/weightlossview";
import wellnesscons from "./components/wellnessconsview";
import supplements from "./components/supplementsview";
import nutrienttherapy from "./components/nutrienttherapyview";
import peptidtab2 from "./components/peptidtab2.vue";
import peptidtab2_1 from "./components/peptidtab2-1.vue";
import state from "./store/state";
export default {
  components: {
    home,
    repos,
    devs,
    contact,
    repo,
    about,
    dev,
    peptidesview,
    weightloss,
    wellnesscons,
    supplements,
    nutrienttherapy,
    peptidtab2,
    peptidtab2_1,
  },
  data() {
    return {
      sharedState: state.$data,
      startY: {},
      valid: true,
      mouse: [],
    };
  },
  methods: {
    startPos(e) {
      e = e.changedTouches ? e.changedTouches[0] : e;
      this.startY = {
        posY: e.pageY,
        time: new Date().getTime(),
      };
    },
    endPos(e) {
      e = e.changedTouches ? e.changedTouches[0] : e;
      var distY = e.pageY - this.startY.posY;
      var elapsedTimeY = new Date().getTime() - this.startY.time;
      if (elapsedTimeY <= 500) {
        if (Math.abs(distY) >= 200 && distY < 0) {
          this.$zircle.goBack();
          this.startY = {};
        } else if (Math.abs(distY) >= 200 && distY > 0) {
          // this.$zircle.goBack()
          this.startY = {};
        }
      }
    },
    backward(e) {
      var vm = this;
      this.mouse.push(e.timeStamp);
      var delta = e.deltaY;
      if (
        delta > 0 &&
        this.valid === true &&
        e.timeStamp - this.mouse[0] > 300
      ) {
        this.valid = false;
        this.mouse = [];
        this.$zircle.goBack();
        setTimeout(function () {
          vm.valid = true;
        }, 1800);
      }
    },
  },
  mounted() {
    var vm = this;
    this.$zircle.config({
      style: {
        theme: "github",
      },
    });
    this.$zircle.setView("home");
    document.body.addEventListener("keyup", function (e) {
      if (e.code === "Escape") return vm.$zircle.goBack();
    });
  },
};
</script>

<style>
body {
  font-family: "Helvetica Now Display", sans-serif !important;
  font-weight: 300;
  color: #f7f7f7;
}

.logo {
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  max-width: 180px;
  width: 100%;
  height: 80px;
  z-index: 9999;
  object-fit: contain;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
}

.title {
  margin-left: 5%;
  position: absolute;
  width: 90%;
  color: #454545;
  font-weight: 700;
  font-size: 32px;
  z-index: 9999;
  opacity: 1;
  line-height: 1.02em;
  pointer-events: none !important;
}

.footer {
  margin-left: 5%;
  position: absolute;
  bottom: 20px;
  width: 90%;
  font-size: 32px;
  pointer-events: none !important;
  color: #454545;
  z-index: 9999;
  opacity: 1;
  text-align: center;
}

.title.home {
  text-align: center;
}

.head-enter {
  opacity: 0;
}

.head-enter-active {
  transition: opacity 2s 0.8s;
}

@import url("https://fonts.googleapis.com/css?family=Google+Sans:300,400,500,700");

@import url("https://use.fontawesome.com/releases/v5.1.0/css/all.css");

.theme-github {
  --shade-color: #8a8f94;
  --primary-color: #454545;
  --accent-color: #454545;
}

a {
  color: #b3b309;
}

.title > a {
  text-decoration: none;
}

.z-canvas {
  background-color: white !important;
  font-family: "Helvetica Now Display", sans-serif;
}

#app {
  position: fixed;
  width: 100%;
  height: 100%;
}

.circleContent {
  padding: 40px;
  height: 100%;
  width: 100%;
}
.circleContent h3 {
  font-size: 22px;
  margin-top: 10px;
  color: #4d610a;
}
.circleContent p {
  font-size: 14px;
  left: 20px;
  color: #000;
}

.circleContent img {
  max-width: 120px;
  margin-top: 10px;
}
/*
  ##Device = Desktops landscape
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) and (orientation: landscape) {
  .title.other {
    text-align: center;
    top: 130px;
    font-size: 30px !important;
    color: #b3b309;
  }

  .title.home {
    text-align: center;
    top: 130px;
    font-size: 40px !important;
  }

  .footer {
    line-height: 1.5em;
  }

  .z-label .inside,
  .pos,
  .sub-label {
    font-size: 18px !important;
  }
}

@media (min-width: 1281px) and (orientation: portrait) {
  .title.other {
    text-align: center;
    top: 480px;
    font-size: 28px !important;
  }

  .title.home {
    text-align: center;
    top: 380px;
    font-size: 40px !important;
  }

  .z-label .inside,
  .pos,
  .sub-label {
    font-size: 16px !important;
  }
}

/*
  ##Device = Laptops, Desktops landscape
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .title.other {
    text-align: center;
    top: 130px;
    font-size: 30px !important;
  }

  .title.home {
    text-align: center;
    top: 50px;
    font-size: 40px !important;
  }

  .footer {
    line-height: 1.5em;
  }

  .z-label .inside,
  .pos {
    font-size: 13px !important;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (width: 375px) and (orientation: portrait) {
  .title.other {
    text-align: center;
    top: 40px;
    font-size: 25px !important;
  }

  .title.home {
    text-align: center;
    top: 40px;
  }

  .footer {
    line-height: 0.5em;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .title.other {
    text-align: center;
    top: 170px;
  }

  .title.home {
    text-align: center;
    top: 220px;
  }

  .z-label .inside,
  .pos {
    font-size: 13px !important;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .title.other {
    text-align: center;
    top: 50px;
  }

  .title.home {
    text-align: center;
    top: 50px;
  }

  .footer {
    line-height: 1.5em;
  }

  .z-label .inside,
  .pos {
    font-size: 13px !important;
  }
}

/* iphone x */
@media (width: 812px) and (height: 375px) {
  .title.other {
    text-align: left;
    margin-left: 30px;
    font-size: 18px;
    width: 30%;
    top: calc(5vh);
  }

  .title.home {
    text-align: left;
    margin-left: 28px;
    top: calc(5vh);
    width: 45%;
  }

  .footer {
    text-align: left;
    margin-left: 20px;
    font-size: 15px;
    width: 30%;
    line-height: 1.5em;
  }
}

/* pixel xl */
@media (width: 823px) and (height: 411px) {
  .title.other {
    text-align: left;
    margin-left: 28px;
    font-size: 18px;
    width: 30%;
    top: calc(5vh);
  }

  .title.home {
    text-align: left;
    margin-left: 28px;
    top: calc(5vh);
    width: 45%;
  }

  .footer {
    text-align: left;
    margin-left: 20px;
    font-size: 15px;
    width: 30%;

    line-height: 1.5em;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .title.other {
    text-align: left;
    margin-left: 30px;
    font-size: 25px;
    width: 30%;
    top: calc(5vh);
  }

  .title.home {
    text-align: left;
    margin-left: 18px;
    font-size: 25px;
    top: calc(5vh);
    width: 40%;
  }

  .footer {
    text-align: left;
    margin-left: 20px;
    font-size: 15px;
    width: 30%;
    line-height: 1.5em;
  }

  .z-label .inside,
  .pos {
    font-size: 13px !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .title.other {
    text-align: center;
    top: 130px;
  }

  .title.home {
    top: 40px;
  }

  .footer {
    line-height: 0.5em;
  }

  .z-label .inside,
  .pos {
    font-size: 13px !important;
  }
}
</style>
