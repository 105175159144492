<template>
  <z-view
    size="xxl"
    style="background-color: white; border-width: 7px"
    :style="'border-color:' + sharedState.colorMe.main"
  >
    <div class="circleContent">
      <h3>IV Nutrient Therapy</h3>
      <p>
        Intravenous nutrient therapy (IV) delivers nutritional support
        (essential vitamins, minerals, amino acids, antioxidants) through an
        intravenous drip so that they are absorbed directly into your
        bloodstream. This delivery system helps you receive optimum
        concentrations of key nutrients to support health and wellbeing. The
        fast rate of absorption means you feel the energizing benefits
        immediately and your body processes this critical nutrition more
        efficiently.
      </p>
      <img
        src="../assets/images/triangle.png"
        alt="Energy Optimization And Recovery"
      />
    </div>

    <div slot="extension">
      <z-spot
        class="meteors"
        :distance="120"
        size="xs"
        label="Contact"
        style="
          color: white;
          border-color: white;
          border-width: 0px;
          background-color: var(--shade-color);
        "
        :angle="45"
        to-view="contact"
      >
        <i style="font-size: 14px; line-height: 60px" class="fas fa-phone"></i>
      </z-spot>
    </div>
  </z-view>
</template>

<script>
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format"; // eslint-disable-line

import state from "../store/state";
export default {
  data() {
    return {
      info: {},
      activePage: true,
      startX: {},
      sharedState: state.$data,
      colors: [
        {
          main: "#54a74c",
          sec: "hsl(115, 37%, 18%)",
        },
        {
          main: "#f2bd00",
          sec: "hsl(47, 100%, 17%)",
        },
        {
          main: "#b3b309",
          sec: "hsl(222, 92%, 25%)",
        },
      ],
    };
  },
  methods: {
    toggle() {
      this.activePage = !this.activePage;
    },
    getOrdinal(n) {
      var s = ["th", "st", "nd", "rd"];
      var v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    toLink(url) {
      return window.open(url, "_blank");
    },
    formatTime(time) {
      return moment(time).format("H:mm");
    },
    plus3(time) {
      var final = moment(time).add(4, "hours").diff(moment(), "minutes");
      return (
        moment(time).add(4, "hours").format("H:mm") +
        " (in " +
        moment.duration(final, "minutes").format("H:mm") +
        ")"
      );
    },
  },
  computed: {
    permanency() {
      if (this.info.stay === 3) {
        return "new!";
      } else if (this.info.stay > 3 && this.info.stay <= 48) {
        return this.info.stay + " h.";
      } else if (this.info.stay > 49) {
        return Math.floor(this.info.stay / 24) + " days";
      }
    },
  },
  created() {
    var params = this.$zircle.getParams().data;
    this.info = {
      updated: params.updated,
      avatar: params.avatar,
      description: params.description,
      url: params.url,
      language: params.language,
      author: params.author,
      name: params.name,
      forks: params.forks,
      stars: params.stars,
      periodStars: params.currentPeriodStars,
      position: params.position,
      diff: params.diff,
      prevPos: params.prevPos,
      stay: params.stay,
    };
  },
  mounted() {},
};
</script>
