<template>
  <z-view
    size="xxl"
    style="border-width: 7px"
    :style="'border-color:' + sharedState.colorMe.main"
  >
    <div class="aboutContent">
      <div class="textBox">
        <p>
          Koniver Wellness is a Performance Medicine Clinic located in beautiful
          Charleston, South Carolina. Since our founding in 2006, Koniver
          Wellness has specialized in delivering world-class medical care and
          helping patients achieve their highest potential so they can perform
          their best at all stages of their life.

          <br />
          We have an elite team of medical providers, nursing staff, and support
          staff dedicated to delivering the best-in-class IV therapy, peptides,
          unique and custom supplements, diagnostic testing, and advanced
          treatment options not available at other clinics. We offer both
          in-person and telemedicine appointments to serve residents of our
          local area and across the nation.
        </p>
      </div>
      <!-- <div class="imgBox">
        <img
          src="https://www.anilkagit.com/wp-content/uploads/2022/05/kahve-ile-cizim-19.jpg"
          alt=""
        />
      </div> -->
    </div>

    <div slot="extension">
      <z-spot
        button
        class="butt2"
        size="s"
        style="
          font-size: 25px;
          color: white;
          border-color: var(--shade-color);
          background-color: var(--shade-color);
        "
        label="Instagram"
        :distance="120"
        :angle="135"
        @click.native="toLink('https://instagram.com')"
      >
        <i class="fab fa-instagram"></i>
      </z-spot>
      <z-spot
        button
        class="butt2"
        size="s"
        style="
          font-size: 25px;
          color: white;
          border-color: var(--shade-color);
          background-color: var(--shade-color);
        "
        label="Twitter"
        :distance="120"
        :angle="45"
        @click.native="toLink('https://x.com')"
      >
        <i class="fab fa-twitter"></i>
      </z-spot>
    </div>
  </z-view>
</template>

<script>
import axios from "axios";

import state from "../store/state";
export default {
  data() {
    return {
      info: {},
      activePage: true,
      startX: {},
      sharedState: state.$data,
      colors: [
        {
          main: "#54a74c",
          sec: "hsl(115, 37%, 18%)",
        },
        {
          main: "#f2bd00",
          sec: "hsl(47, 100%, 17%)",
        },
        {
          main: "#b3b309",
          sec: "hsl(222, 92%, 25%)",
        },
      ],
    };
  },
  methods: {
    toLink(url) {
      return window.open(url, "_blank");
    },
    getZircle() {
      return axios
        .get("https://api.github.com/repos/raijoffle/forie-vue-new")
        .then(function (response) {
          return {
            avatar: response.data.owner.avatar_url,
            description: response.data.description,
            url: response.data.html_url,
            language: response.data.language,
            author: response.data.owner.login,
            name: response.data.name,
            forks: response.data.forks_count,
            stars: response.data.stargazers_count,
          };
        });
    },
  },
  computed: {},
  mounted() {
    var vm = this;
    this.getZircle().then(function (e) {
      vm.info = e;
    });
  },
};
</script>

<style>
.emit {
  opacity: 0;
  animation: emit-pulse 2000ms ease-out;
  animation-iteration-count: 5;
}

@keyframes emit-pulse {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.label {
  font-weight: 500;
  position: absolute;
  top: 32%;
  display: flex;
  align-items: bottom;
  justify-content: center;
  left: 0;
  padding-top: 30px;
  margin-left: 10%;
  word-break: break-word;
  width: 80%;
  height: 40%;
  font-size: calc(10px + 0.8vw);
  overflow: hidden;
  max-lines: 3;
}

.sub-label {
  width: 55%;
  font-weight: 400;
  display: flex;
  align-items: top;
  justify-content: center;
  font-size: calc(7px + 0.8vw);
}

.super-label {
  font-weight: 700;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  padding: 10px;
  padding-top: 30%;
  margin-left: 10%;
  word-break: break-all;
  width: 80%;
  height: 30%;
  font-size: calc(15px + 0.8vw);
}

.icons {
  font-size: calc(1vw + 1.2vh + 1.2vmin);
}

.aboutContent {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #b3b309;
  top: 0;
  left: 0;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.aboutContent h2 {
  font-size: 16px;
  color: #fff;
}

.aboutContent p {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  word-wrap: break-word;
}
.aboutContent .textBox {
  height: 100%;
  width: 100%;
  border-radius: 50% 50% 0 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  padding: 60px 40px;
  box-sizing: border-box;
}
.aboutContent .imgBox {
  width: 100%;
  height: 50%;
  position: relative;
  bottom: 0;
}

.aboutContent .imgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
