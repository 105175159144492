<template>
  <z-view
    size="xxl"
    style="background-color: white; border-width: 7px"
    :style="'border-color:' + sharedState.colorMe.main"
  >
    <div class="circleContent">
      <h3>Advanced Oral Supplementations</h3>
      <p>
        Our range of extraordinary supplements is designed to help you optimize
        your health and performance from within. We build together potent
        formulas with different amino acids, vitamins, and antioxidants that are
        all developed through scientific research and created with results in
        mind.
      </p>
      <img
        src="../assets/images/oral-supplements-.jpg"
        alt="Energy Optimization And Recovery"
      />
    </div>

    <div slot="extension">
      <z-spot
        class="meteors"
        :distance="120"
        size="xs"
        label="Contact"
        style="
          color: white;
          border-color: white;
          border-width: 0px;
          background-color: var(--shade-color);
        "
        :angle="45"
        to-view="contact"
      >
        <i style="font-size: 14px; line-height: 60px" class="fas fa-phone"></i>
      </z-spot>
    </div>
  </z-view>
</template>

<script>
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format"; // eslint-disable-line

import state from "../store/state";
export default {
  data() {
    return {
      info: {},
      activePage: true,
      startX: {},
      sharedState: state.$data,
      colors: [
        {
          main: "#54a74c",
          sec: "hsl(115, 37%, 18%)",
        },
        {
          main: "#f2bd00",
          sec: "hsl(47, 100%, 17%)",
        },
        {
          main: "#b3b309",
          sec: "hsl(222, 92%, 25%)",
        },
      ],
    };
  },
  methods: {
    toggle() {
      this.activePage = !this.activePage;
    },
    getOrdinal(n) {
      var s = ["th", "st", "nd", "rd"];
      var v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    toLink(url) {
      return window.open(url, "_blank");
    },
    formatTime(time) {
      return moment(time).format("H:mm");
    },
    plus3(time) {
      var final = moment(time).add(4, "hours").diff(moment(), "minutes");
      return (
        moment(time).add(4, "hours").format("H:mm") +
        " (in " +
        moment.duration(final, "minutes").format("H:mm") +
        ")"
      );
    },
  },
  computed: {
    permanency() {
      if (this.info.stay === 3) {
        return "new!";
      } else if (this.info.stay > 3 && this.info.stay <= 48) {
        return this.info.stay + " h.";
      } else if (this.info.stay > 49) {
        return Math.floor(this.info.stay / 24) + " days";
      }
    },
  },
  created() {
    var params = this.$zircle.getParams().data;
    this.info = {
      updated: params.updated,
      avatar: params.avatar,
      description: params.description,
      url: params.url,
      language: params.language,
      author: params.author,
      name: params.name,
      forks: params.forks,
      stars: params.stars,
      periodStars: params.currentPeriodStars,
      position: params.position,
      diff: params.diff,
      prevPos: params.prevPos,
      stay: params.stay,
    };
  },
  mounted() {},
};
</script>
